/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */
// Because we want these symbols to be in the root package, for better usage from JS
@file:Suppress("PackageDirectoryMismatch")

package vc_js

import io.ktor.client.engine.HttpClientEngine
import io.ktor.client.engine.HttpClientEngineConfig
import io.ktor.client.plugins.api.createClientPlugin
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.HttpRequestData
import io.ktor.client.request.HttpResponseData
import io.ktor.client.request.port
import io.ktor.http.URLProtocol
import io.ktor.http.authority
import io.ktor.http.path
import io.ktor.utils.io.InternalAPI
import io.ktor.util.putAll
import kotlinx.coroutines.CoroutineDispatcher
import kotlin.coroutines.CoroutineContext

/**
 * A Ktor plugin that proxies all requests to a configured proxy.
 * Made to be used with NPM's 'http-proxy-middleware' based proxies.
 */
class ProxyPluginConfig {
    var scheme: URLProtocol = URLProtocol.HTTP
    var host: String = "localhost"
    var port: Int = 1234
    var headerName = "ProxyTo"
    var pathPrefix = "proxy"
}

val ProxyPlugin = createClientPlugin("ProxyPlugin", ::ProxyPluginConfig) {
    val config = pluginConfig
    onRequest { req, _ ->
        val header = "${req.url.protocol.name}://${req.url.authority}"
        req.headers[config.headerName] = header
        req.url.protocol = config.scheme
        req.url.host = config.host
        req.port = config.port
        req.url.path("${config.pathPrefix}${req.url.pathSegments.joinToString("/")}")
    }
}

// TODO: decide if we need both a proxy plugin and a proxy engine or if only one of these is required
class ProxyEngine(
    private val inner: HttpClientEngine,
    private val proxyConfig: ProxyPluginConfig
) : HttpClientEngine {

    override val config: HttpClientEngineConfig
        get() = inner.config
    override val coroutineContext: CoroutineContext
        get() = inner.coroutineContext
    override val dispatcher: CoroutineDispatcher
        get() = inner.dispatcher

    override fun close() {
        inner.close()
    }

    @InternalAPI // because apparently an engine needs to implement internal APIs
    override suspend fun execute(data: HttpRequestData): HttpResponseData {
        val newReq = HttpRequestBuilder().apply {
            method = data.method
            url.protocol = proxyConfig.scheme
            url.host = proxyConfig.host
            url.port = proxyConfig.port
            url.path("${proxyConfig.pathPrefix}${data.url.pathSegments.joinToString("/")}")

            headers.appendAll(data.headers)

            val header = "${data.url.protocol.name}://${data.url.authority}"
            headers.append(proxyConfig.headerName, header)
            attributes.putAll(data.attributes)
            body = data.body
        }.build()
        return inner.execute(newReq)
    }
}
