/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

package io.curity.ssi.sdjwt

import kotlin.jvm.JvmStatic

fun JsonPath?.withProperty(name: String) = this?.withProperty(name) ?: JsonPath(JsonPath.Segment.Property(name))

/**
 * Represents a path into a JSON node, with support for wildcards on properties and indexes.
 */
/*
 * Implementation note:
 * The path is represented as a linked list of path segments, starting at the *last* segment and ending at the first
 * segment. This representation was chosen so that incrementally building a path by adding a segment to another path
 * only requires creating a new node, because the prefix path list can be used.
 * We may want to change this internal representation if the path usage patterns are changed in the future.
 */
data class JsonPath(
    val current: Segment,
    val previous: JsonPath? = null,
) {
    val depth: Int = previous?.depth?.plus(1) ?: 1

    // for debug purposes
    override fun toString() = when (previous) {
        null -> "/$current"
        else -> "${previous}/$current"
    }

    sealed interface Segment {

        fun matches(other: Segment): Boolean

        data class Property(
            val name: String,
        ) : Segment {
            override fun matches(other: Segment) = this == other || other is AnyProperty
            override fun toString() = name
        }

        data class ArrayIndex(
            val index: Int,
        ) : Segment {
            override fun matches(other: Segment) = this == other || other is AnyIndex
            override fun toString() = "[$index]"
        }

        data object AnyProperty : Segment {
            override fun matches(other: Segment) = this == other || other is Property
            override fun toString() = "*"
        }

        data object AnyIndex : Segment {
            override fun matches(other: Segment) = this == other || other is ArrayIndex
            override fun toString() = "[*]"
        }
    }

    fun with(segment: Segment) = JsonPath(segment, this)
    fun withProperty(name: String) = with(Segment.Property(name))
    fun withIndex(index: Int) = with(Segment.ArrayIndex(index))
    fun withAnyIndex() = with(Segment.AnyIndex)
    fun withAnyProperty() = with(Segment.AnyProperty)

    fun withSubPath(pathString: String): JsonPath {
        val parts = pathString.split("/")
            .filter { it.isNotBlank() }
        if (parts.isEmpty()) {
            return this
        }
        return parts.map { build(it) }.fold(this) { acc, segment ->
            acc.with(segment)
        }
    }

    fun withProperties(names: List<String>) = names.fold(this) { acc, name ->
        acc.withProperty(name)
    }

    fun matches(other: JsonPath): Boolean {
        if (depth != other.depth) {
            return false
        }
        var path1: JsonPath? = this
        var path2: JsonPath? = other
        while (path1 != null && path2 != null) {
            if (!path1.current.matches(path2.current)) {
                return false
            }
            path1 = path1.previous
            path2 = path2.previous
        }
        return true
    }

    companion object {

        @JvmStatic
        fun ofRootProperty(name: String) = JsonPath(Segment.Property(name))

        private fun buildFromSegments(segments: List<Segment>): JsonPath {
            val iterator = segments.iterator()
            if (!iterator.hasNext()) {
                throw IllegalArgumentException("segment list cannot be empty")
            }
            var current = JsonPath(iterator.next())
            while (iterator.hasNext()) {
                current = JsonPath(iterator.next(), current)
            }
            return current
        }

        @JvmStatic
        fun parse(pathString: String): JsonPath? {
            val parts = pathString.split("/")
                .filter { it.isNotBlank() }
            if (parts.isEmpty()) {
                return null
            }
            return build(parts)
        }

        @JvmStatic
        fun build(vararg segments: Any): JsonPath = buildFromSegments(
            segments.map {
                build(it)
            }
        )

        fun build(segments: List<Any>): JsonPath = buildFromSegments(
            segments.map {
                build(it)
            }
        )

        private fun build(segment: Any): Segment = when (segment) {
            is Int -> {
                Segment.ArrayIndex(segment.toInt())
            }

            is String -> {
                when (segment) {
                    ANY_INDEX -> Segment.AnyIndex
                    ANY_PROPERTY -> Segment.AnyProperty
                    else -> Segment.Property(segment)
                }
            }

            else -> {
                throw IllegalArgumentException("segment must be Int or String, however it was ${segment::class.simpleName}")
            }
        }

        private const val ANY_INDEX = "[*]"
        private const val ANY_PROPERTY = "*"
    }
}
